@import url("https://fonts.googleapis.com/css2?family=Ultra&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nanum+Myeongjo&family=Ultra&display=swap");

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Ultra",
    "Nanum Myeongjo", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  width: 100vw;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* phone-599 */
@media screen and (max-width: 1049px) {
  /* hide scroll */
  body::-webkit-scrollbar {
    display: none;
  }
}
.inter {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: -0.03em;
}

.notokr {
  font-family: "Noto Sans KR", sans-serif;
  letter-spacing: -0.04em;
}

.shrik {
  font-family: "Shrikhand", serif;
  letter-spacing: -0.02em;
}

.briem {
  font-family: "Briem Hand", serif;
  letter-spacing: -0.03em;
}

.times {
  font-family: "Times New Roman", serif;
  letter-spacing: -0.03em;
}

.fs-11 {
  font-size: 11px;
}

.fs-12 {
  font-size: 12px;
}

.fs-13 {
  font-size: 13px;
}

.fs-14 {
  font-size: 14px;
}

.fs-16 {
  font-size: 16px;
}

.fs-18 {
  font-size: 18px;
}

.fs-20 {
  font-size: 20px;
}

.fs-24 {
  font-size: 24px;
}

.fs-28 {
  font-size: 28px;
}

.fs-32 {
  font-size: 32px;
}

.fs-36 {
  font-size: 36px;
}

.fs-48 {
  font-size: 48px;
}

.fs-54 {
  font-size: 54px;
}

.fs-60 {
  font-size: 60px;
}

.fs-64 {
  font-size: 64px;
}

.fs-76 {
  font-size: 76px;
}

.fs-96 {
  font-size: 96px;
}

.fw-300 {
  font-weight: 300;
}

.fw-350 {
  font-weight: 350;
}

.fw-400 {
  font-weight: 400;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.fw-700 {
  font-weight: 700;
}

.fw-800 {
  font-weight: 800;
}

.lh-2 {
  line-height: 2;
}

.lh-1\.6 {
  line-height: 1.6;
}

.lh-1\.7 {
  line-height: 1.7;
}

.lh-1\.9 {
  line-height: 1.9;
}

@media (max-width: 1049px) {
  .tablet\:fs-9 {
    font-size: 9px;
  }

  .tablet\:fs-10 {
    font-size: 10px;
  }

  .tablet\:fs-11 {
    font-size: 11px;
  }

  .tablet\:fs-12 {
    font-size: 12px;
  }

  .tablet\:fs-14 {
    font-size: 14px;
  }

  .tablet\:fs-16 {
    font-size: 16px;
  }

  .tablet\:fs-18 {
    font-size: 18px;
  }

  .tablet\:fs-20 {
    font-size: 20px;
  }

  .tablet\:fs-24 {
    font-size: 24px;
  }

  .tablet\:fs-28 {
    font-size: 28px;
  }

  .tablet\:fs-32 {
    font-size: 32px;
  }

  .tablet\:fs-36 {
    font-size: 36px;
  }

  .tablet\:fs-48 {
    font-size: 48px;
  }

  .tablet\:fs-60 {
    font-size: 60px;
  }

  /* lineheight */
  .tablet\:lh-1 {
    line-height: 1;
  }

  .tablet\:lh-1\.5 {
    line-height: 1.5;
  }

  .tablet\:lh-1\.6 {
    line-height: 1.6;
  }
}

@media (max-width: 799px) {
  .phablet\:fs-9 {
    font-size: 9px;
  }

  .phablet\:fs-10 {
    font-size: 10px;
  }

  .phablet\:fs-11 {
    font-size: 11px;
  }

  .phablet\:fs-12 {
    font-size: 12px;
  }

  .phablet\:fs-14 {
    font-size: 14px;
  }

  .phablet\:fs-16 {
    font-size: 16px;
  }

  .phablet\:fs-18 {
    font-size: 18px;
  }

  .phablet\:fs-20 {
    font-size: 20px;
  }

  .phablet\:fs-24 {
    font-size: 24px;
  }

  .phablet\:fs-28 {
    font-size: 28px;
  }

  .phablet\:fs-32 {
    font-size: 32px;
  }

  .phablet\:fs-36 {
    font-size: 36px;
  }

  .phablet\:fs-48 {
    font-size: 48px;
  }

  /* lineheight */
  .phablet\:lh-1 {
    line-height: 1;
  }
  .phablet\:lh-1\.2 {
    line-height: 1.2;
  }

  .phablet\:lh-1\.5 {
    line-height: 1.5;
  }

  .phablet\:lh-1\.6 {
    line-height: 1.6;
  }
}

@media (max-width: 599px) {
  .phone\:fs-9 {
    font-size: 9px;
  }

  .phone\:fs-10 {
    font-size: 10px;
  }

  .phone\:fs-11 {
    font-size: 11px;
  }

  .phone\:fs-12 {
    font-size: 12px;
  }

  .phone\:fs-14 {
    font-size: 14px;
  }

  .phone\:fs-16 {
    font-size: 16px;
  }

  .phone\:fs-18 {
    font-size: 18px;
  }

  .phone\:fs-20 {
    font-size: 20px;
  }

  .phone\:fs-24 {
    font-size: 24px;
  }

  .phone\:fs-28 {
    font-size: 28px;
  }

  .phone\:fs-32 {
    font-size: 32px;
  }

  .phone\:fs-36 {
    font-size: 36px;
  }

  .phone\:fs-48 {
    font-size: 48px;
  }

  /* lineheight */
  .phone\:lh-1 {
    line-height: 1;
  }

  .phone\:lh-1\.5 {
    line-height: 1.5;
  }

  .phone\:lh-1\.6 {
    line-height: 1.6;
  }
}
